import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import axios from 'axios';
import showdown from "showdown"
import ReactMarkdown from 'react-markdown';

export default function CompanyUnlistedForm({ setBannerState }) {
  const [postingData, setPostingData] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [notificationEmail, setNotificationEmail] = useState('');
  const [formData, setFormData] = useState({
    company: '',
    companyAddress: '',
    postcode: '',
    companyEmail: '',
    companyPhone: '',
    staffNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    consent: 'yes'
  });

  // = = = = = = = = 
  // Forestry Query
  const data = useStaticQuery(graphql`
    query UnlistedForm {
      unlistedForm: allMarkdownRemark(filter: {frontmatter: {form_title: {eq: "Unlisted Company Form"}}}) {
        nodes {
          frontmatter {
            form_consent
          }
        }
      },
      confirmationEmail: markdownRemark(frontmatter: {email_name: {eq: "Company Unlisted Confirmation - User"}}) {
        html
      }
      notificationEmail: markdownRemark(frontmatter: {email_name: {eq: "Company Unlisted Notification - Organization"}}) {
        html
      }
    }
  `)

  // const confirmationTemplate = data.confirmationEmail.html;
  // const notificationTemplate = data.notificationEmail.html;


  const notificationSubject = "Speak for the City: Notification - New Registration Submition";
  const notificationName = "companyUnlisted.notification";
  const notificationDescription = "Template for Notification of the Company Unlisted Form";
  const notificationTemplate = `
    <html>
      <body>
        <h1>Here is the submited information</h1>
        <h2>A message from</h2>
        <p>Name: {{userName}}</p>
        <p>Email: {{userEmail}}</p>
        <p>Phone: {{userPhone}}</p>
        <p>Organisation: {{companyName}}</p>
      </body>
    </html>
  `;
  const confirmationSubject = "Speak for the City: Confirmation - New Registration Submition";
  const confirmationName = "companyUnlisted.confirmation";
  const confirmationDescription = "Template for Confirmation of the Company Unlisted Form";
  const confirmationTemplate = `
    <html>
      <body>
        <p>Dear {{userName}}</p>
        <p>Thank you for registering. We will get in thouch with you soon</p>
        <p>Kind regards,</p>
        <p>Speak for the City</p>
      </body>
    </html>
  `;
  
  const templateVariables = {
    userName: `${formData.firstName} ${formData.lastName}`,
    userEmail: formData.email,
    userPhone: formData.phone,
    userConsent: formData.consent,
    companyName: formData.company,
  };

  const notificationMailgun = {notificationSubject, notificationName, notificationDescription, notificationTemplate, templateVariables};
  const confirmationMailgun = {confirmationSubject, confirmationName, confirmationDescription, confirmationTemplate, templateVariables};


  // = = = = = = = = 
  // Utils Functions

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  const htmlFormConsent = data.unlistedForm.nodes[0].frontmatter.form_consent;


  // = = = = = = = = 
  // Events Functions

  // Add data to States
  const handleChange = e => {
    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent === undefined) {
      setFormData({
        ...formData,
        consent: 'yes'
      });
    }
  }

  // Handles Form Submition
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);
    
    // Send to Netlify Forms
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then( async (response) => {
      if (!response.ok) throw Error(response.statusText);
      // Send to Serverless Function
      axios.post('/.netlify/functions/sendCompanyData', {
        userName: `${formData.firstName} ${formData.lastName}`,
        userEmail: formData.email,
        userPhone: formData.phone,
        companyName: formData.company,
        companyAddress: formData.companyAddress,
        companyEmail: formData.companyEmail,
        companyPhone: formData.companyPhone,
      })
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
      // Empty Form to clean up Form Fields
      setFormData({
        company: '',
        companyAddress: '',
        postcode: '',
        companyEmail: '',
        companyPhone: '',
        registrationID: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        consent: 'yes'
      });
      // Go to final page
      setBannerState('final');
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  return (
    <form 
      onSubmit={ (e) => handleSubmit(e) } 
      className="form form--unlisted"
      name="Unlisted Organisation Form"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      id="unlisted-organisation-form"
    >
      <input type="hidden" name="form-name" value="Unlisted Organisation Form" />

      <div className="field-group">
        <div className="input-wrapper input-wrapper--company">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.company} 
            name="company" 
            id="company" 
            placeholder="Organisation name*"
            required
          />
          <span className={`input__custom-label ${ formData.company ? 'show' : '' }`}>Organisation Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.companyAddress} 
            name="companyAddress" 
            className="required companyAddress" 
            id="companyAddress" 
            placeholder="Workplace address*"
            required
          />
          <span className={`input__custom-label ${ formData.companyAddress ? 'show' : '' }`}>Company Address</span>
        </div>
        
        <div className="input-wrapper input-wrapper--postcode">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.postcode} 
            name="postcode" 
            className="required postcode" 
            id="postcode" 
            placeholder="Postcode*"
            required
          />
          <span className={`input__custom-label ${ formData.postcode ? 'show' : '' }`}>Postcode</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.companyEmail} 
            name="companyEmail" 
            className="companyEmail" 
            id="companyEmail" 
            placeholder="Workplace Email*"
            required
          />
          <span className={`input__custom-label ${ formData.companyEmail ? 'show' : '' }`}>Work Email</span>
        </div>

        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.companyPhone} 
            name="companyPhone" 
            className="companyPhone" 
            id="companyPhone" 
            placeholder="Workplace Phone*"
          />
          <span className={`input__custom-label ${ formData.companyPhone ? 'show' : '' }`}>Work Phone</span>
        </div>
      </div>

      <div className="field-group">
        <label>Number of staff at this workplace</label>
        <div className="input-wrapper input-wrapper--registrationId">
          <input 
            onChange={(e) => handleChange(e)} 
            type="number" 
            value={formData.staffNumber} 
            name="staffNumber" 
            className="staffNumber" 
            id="staffNumber" 
            placeholder=""
          />
          <span className={`input__custom-label ${ formData.staffNumber ? 'show' : '' }`}>Number of Staff</span>
        </div>
      </div>

      <p>In order to help you register to vote, please provide us with your contact details</p>

      <div className="field-group">
      <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.firstName} 
            name="firstName" 
            id="first-name" 
            placeholder="First Name*"
            required
          />
          <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>First Name</span>
        </div>

        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.lastName} 
            name="lastName" 
            id="last-name" 
            placeholder="Last Name*"
            required
          />
          <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Last Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="email" 
            value={formData.email} 
            name="email" 
            className="required email" 
            id="email" 
            placeholder="Work Email*"
            required
          />
          <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Work Email</span>
        </div>

        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="tel" 
            value={formData.phone} 
            name="phone" 
            className="required phone" 
            id="phone" 
            placeholder="Mobile Phone"
          />
          <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Mobile Phone</span>
        </div>
      </div>

      <div className="form__consent">
        <ReactMarkdown className="consent__text">{htmlFormConsent}</ReactMarkdown>
        <div className="consent__inputs">

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="yes" 
              name="consent" 
              type="radio" 
              value="yes" 
              checked={ (formData.consent === 'yes' || formData.consent === undefined) ? true : false} 
            />
            <label htmlFor="yes">Yes</label>
          </div>

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="no" 
              name="consent" 
              type="radio" 
              value="no" 
              checked={ formData.consent === 'no' ? true : false} 
            />
            <label htmlFor="no">No</label>
          </div>

        </div>  
      </div>

      <div className="button-group">
        {/* <button type="submit">Submit</button> */}
        <div className="button button--submit">
          {
            postingData !== true
              ? <input readOnly type="submit" value="Submit" name="submit" id="company-submit" />
              
              : <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
          }
        </div>
      </div>      
    </form>
  )
}