import React, { useContext, useState, useEffect } from 'react';
import IconSelect from '../icons/IconSelect';
import countryList from './Countries'
import ReactMarkdown from 'react-markdown';
//todo marcel
export default function CompanyEmailFormStep2 ({ htmlFormConsent, setStep, formData, handleChange, postingData, handleSubmit }) {
	const [errorMessage, setErrorMessage] = useState('');
  const [inputError, setInputError] = useState('');


  const handleBackButton = (e) => {
    e.preventDefault();
     // Scroll to the top of the window so user see the form Form
    if (typeof window !== `undefined`) { 
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

		setStep(1);
  }

  const handleClick = (e) => {
		
    if(formData.workingSince == 'None'){
      e.preventDefault();
      setErrorMessage('*If none of the above apply, you are not eligible to register as a voter this year');
      return;
    }
		if(!formData.nationality) {
			e.preventDefault();
      setInputError('nationality');
      setErrorMessage('*You must be an EU or Commonwealth citizen in order to register');
      return;
    }
    

  }

  return(
    <>
      <div className="field-group">
        <fieldset className="fieldset-wrapper ">
          <legend>Please select one:</legend>

					<div className="checkbox-wrapper custom-radio">
						<label className="checkbox-container">I have worked continuously for this organisation in the City since September last year.
							
					
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
								value="I have worked continuously for this organisation in the City since September last year." 
								checked={ formData.workingSince === 'I have worked continuously for this organisation in the City since September last year.' ? true : false} />
					</div>
					
					<div className="checkbox-wrapper custom-radio">
						<label className="checkbox-container">I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.
							
							{/* <span className="checkmark"></span> */}
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
								value="I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years." 
								checked={ formData.workingSince === 'I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.' ? true : false} />
					</div>
					
					<div className="checkbox-wrapper custom-radio">
						<label className="checkbox-container">I have worked in the City for any organisation - for a combined period of ten years or more.
							
							
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
								value="I have worked in the City for any organisation - for a combined period of ten years or more." 
								checked={ formData.workingSince === 'I have worked in the City for any organisation - for a combined period of ten years or more.' ? true : false} />
					</div>

					<div className="checkbox-wrapper custom-radio">
						<label className="checkbox-container">I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.
							
							
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
								value="I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year." 
								checked={ formData.workingSince === 'I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.' ? true : false} />
					</div>

          <div className="checkbox-wrapper custom-radio">
						<label className="checkbox-container">None of the above
							
			
						</label>
            <input 
								onChange={ (e) => handleChange(e) } 
								type="radio"
								name="workingSince"
								value="None" 
								checked={ formData.workingSince === 'None' ? true : false} />
					</div>

        </fieldset>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <label htmlFor="select-standing" className="select__label">Citizenship (required):</label>
          
          <div className="select__wrapper  select--nationality">
            
            <select  
							onChange={ (e) => handleChange(e) } 
							className={`select__input ${inputError ===  "nationality" ? 'alert-border' : null}`} 
							name="nationality" 
							id="select-nationality"
							value={formData.nationality ? formData.nationality : ''}
						>
              <option className="select__option" value="">Select</option>
              {
                countryList.map(country => <option key={country} className="select__option" value={country}>{country}</option>)
              }
            </select>
            
            <div className="select__icon">
              <IconSelect />
            </div>
            
          </div>
          <span className="small">Please note, only UK, EU and Commonwealth citizens can register to vote in UK elections</span>
        </div>
      </div>


      <p className="field-intro">If we have a voter registration contact at your organisation, we will send them this information. In the event we do not, could you suggest an appropriate person at your organisation, who we could contact about this? (optional).</p>
      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactName ? formData.contactName : ""} 
            name="contactName"
            id="contact-job-title" 
            placeholder="Name"
          />
          <span className={`input__custom-label ${ formData.contactName ? 'show' : '' }`}>Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactEmail ? formData.contactEmail : ""} 
            name="contactEmail"
            id="contact-email" 
            placeholder="Email"
          />
          <span className={`input__custom-label ${ formData.contactEmail ? 'show' : '' }`}>Email</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactJobTitle ? formData.contactJobTitle : ""} 
            name="contactJobTitle"
            id="contact-job-title" 
            placeholder="Job title"
          />
          <span className={`input__custom-label ${ formData.contactJobTitle ? 'show' : '' }`}>Job title</span>
        </div>
      </div>

      <div className="form__consent">
        <ReactMarkdown className="consent__text">{htmlFormConsent}</ReactMarkdown>
        <div className="consent__inputs">

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="yes" 
              name="consent" 
              type="radio" 
              value="yes" 
              checked={ (formData.consent === 'yes' || formData.consent === undefined) ? true : false} 
            />
            <label htmlFor="yes">Yes</label>
          </div>

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="no" 
              name="consent" 
              type="radio" 
              value="no" 
              checked={ formData.consent === 'no' ? true : false} 
            />
            <label htmlFor="no">No</label>
          </div>

        </div>  
      </div>

			<div className="alert-wrapper">
        <p className="alert-text">{errorMessage}</p>
      </div>

      <div className="button-group">
        <div className="button button--back">
          <button onClick={(e) => handleBackButton(e)} className="button">Back</button>
        </div>
        <div className="button button--submit">
          {
            postingData !== true
              ? <input onClick={(e) => handleClick(e)} readOnly type="submit" value="Submit" name="submit" id="company-submit" />
              
              : <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
          }
        </div>
      </div>
    </>
  )
}