import React, { useContext, useState, useEffect } from 'react';

export default function CompanyEmailFormStep1 ({ setQuery, setBannerState, formIntro, setStep, selectedCompany, formData, handleChange}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [inputError, setInputError] = useState('');

  const handleBackButton = (e) => {
    e.preventDefault();
    setQuery('');
    setBannerState("initial");
     // Scroll to the top of the window so user see the form Form
    if (typeof window !== `undefined`) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  const handleClick = (e, n) => {
    e.preventDefault();
    if(!formData.firstName) {
      setInputError('firstName');
      setErrorMessage('*First Name is required');
      return;
    }
    if(!formData.lastName) {
      setInputError('lastName');
      setErrorMessage('*Last Name is required');
      return;
    }
    if(!formData.email) {
      setInputError('email');
      setErrorMessage('*Email is required');
      return;
    }
    setStep(n);
  }

  return (
    <>
      <p className="form__intro">{ formIntro }</p>

      <div className="field-group">
        <div className="input-wrapper input-wrapper--company">
          <input 
            type="text" 
            className="input-block"
            value={selectedCompany.name} 
            name="company"
            id="company" 
            placeholder={selectedCompany.name}
            required
            readOnly
          />
          <span className={`input__custom-label ${ selectedCompany.name ? 'show' : '' }`}>Organisation Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input
            className={inputError === "firstName" ? 'alert-border' : null} 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.firstName} 
            name="firstName" 
            id="first-name" 
            placeholder="First Name*"
            required
          />
          <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>Name</span>
        </div>
        
        <div className="input-wrapper">
          <input
            className={inputError === "lastName" ? 'alert-border' : null} 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.lastName} 
            name="lastName" 
            id="last-name" 
            placeholder="Last Name*"
            required
          />
          <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Lastname</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input
            className={inputError ===  "email" ? 'alert-border' : null} 
            onChange={(e) => handleChange(e)} 
            type="email" 
            value={formData.email} 
            name="email" 
            id="email" 
            placeholder="Work Email*"
            required
          />
          <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Work Email</span>
        </div>

        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="tel" 
            value={formData.phone ? formData.phone : ""} 
            name="phone" 
            id="phone" 
            placeholder="Mobile Phone"
          />
          <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Mobile Phone</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input
            type="text" 
            className="input-block"
            value={selectedCompany.addressShort} 
            name="address" 
            id="address" 
            placeholder={selectedCompany.addressShort}
            required
            readOnly
          />
          <span className={`input__custom-label ${ selectedCompany.addressShort ? 'show' : '' }`}>Work Address</span>
        </div>

        <div className="input-wrapper input-wrapper--postcode">
          <input
            type="text" 
            className="input-block"
            value={selectedCompany.postCode} 
            name="postcode" 
            id="postcode" 
            placeholder={`${selectedCompany.postCode}`}
            required
            readOnly
          />
          <span className={`input__custom-label ${ selectedCompany.postCode ? 'show' : '' }`}>Postcode</span>
        </div>
      </div>

      <div className="alert-wrapper">
        <p className="alert-text">{errorMessage}</p>
      </div>

      <div className="button-group">
        <div className="button button--back">
          <button onClick={(e) => handleBackButton(e)} className="button">Back</button>
        </div>
        <div className="button button--submit">
          <button onClick={(e) => handleClick(e, 2)} className="button">Next</button>
        </div>
      </div>
    </>    
  )
}